import React, { useState, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { RiMailLine, RiLockLine } from "react-icons/ri";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import Backdrop from "@mui/material/Backdrop";
import CSVReader from "react-csv-reader";
import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import {
  onSnapshot,
  collection,
  updateDoc,
  addDoc,
  FieldValue,
  query,
  getDocs,
  doc,
  where,
  runTransaction,
} from "firebase/firestore";

import "react-dropdown/style.css";
import {
  Button,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Card,
  Alert,
  Modal,
} from "react-bootstrap";
import ReactLoading from "react-loading";
import db from "../../firebase";
import NavbarPag from "../../components/NavbarPag";
import { useData } from "../../contexts/DataContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let Dashboard = (): any => {
  const { rutInit } = useData();
  const navigate = useNavigate();
  useEffect(() => {
    if (rutInit === "") {
      navigate("/");
    }
  }, []);
  const [lastNumber, setLastNumber] = useState(0);
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [erroMsg, setErrorMsg] = useState("Faltan datos obligatorios");
  const [loading, setLoading] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [table, setTable] = useState(false);
  const location = useLocation();
  const [colDefs, setColDefs] = useState([
    { field: "Id" },
    { field: "Guia" },
    { field: "Destinatario" },
    { field: "Direccion" },
    { field: "Ciudad" },
    { field: "Contacto" },
    { field: "Bultos" },
    { field: "Kilos" },
    { field: "Alto" },
    { field: "Ancho" },
    { field: "Largo" },
    { field: "Observaciones" },
    { field: "Destino" },
  ]);
  const handlePassword = () => {
    setShowPass(!showPass);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoadingOrders(true);
  };

  const getDate = async () => {
    const uuidRef = doc(db, "uuid", "1");
    try {
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(uuidRef);

        const newPopulation = sfDoc.data()?.uuid;
        setLastNumber(newPopulation);
        let nunt = newPopulation + orders.length;
        transaction.update(uuidRef, { id: 0, uuid: nunt });
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.log("Transaction failed: ", e);
    }
  };

  const handleNew = async () => {
    await getDate();
  };

  const newOrders = async () => {
    const ordersRef = collection(db, "orders");
    const deliveries = collection(db, "deliveries");
    //const uuidRef = doc(db, "uuid", "1");

    const ordersxusersRef = collection(db, "ordersxusers");
    var options: any = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const now = new Date().toLocaleDateString("es-ES", options).toUpperCase();
    console.log("trace dia", now);

    const dia = now.split("GMT")[0];
    let textOrder: any = [];
    let nunOr = 0;
    orders.map((i, index) => {
      console.log(index, ":", index % 50);
      if (index % 50 === 0) {
        textOrder.push([]);
        nunOr++;
      }
      let ud2 = lastNumber + index;
      textOrder[nunOr - 1].push(`${ud2}:${i.Bultos}`);
      const payload = {
        uuid: lastNumber + index,
        id: i.Id,
        alto: i.Alto,
        ancho: i.Ancho,
        bultos: i.Bultos,
        ciudad: i.Ciudad,
        contacto: i.Contacto,
        destinatario: i.Destinatario,
        direccion: i.Direccion,
        email: location.state.email,
        guia: i.Guia,
        kilos: i.Kilos,
        largo: i.Largo,
        observaciones: i.Observaciones,
        fecha: dia,
        Destino: i.Destino,
      };
      const timestamp = new Date().getTime();
      const payload2 = {
        uuid: lastNumber + index,
        fecha: timestamp,
        fechaReceived: 0,
        Destino: i.Destino,
        state: 0,
        receivingStatus: 0,
        bultos: i.Bultos,
        receivingBultos: 0,
        email: location.state.email,
      };
      addDoc(ordersRef, payload);
      addDoc(deliveries, payload2);
    });

    console.log("lastNumber:", lastNumber);

    const q = query(
      collection(db, "listClientNumberOrder"),
      where("email", "==", location.state.email)
    );
    console.log(location.state.email);
    const numberOfOrder = await getDocs(q);
    console.log("numberOfOrder:", numberOfOrder);
    const info: any = [];
    numberOfOrder.forEach((doc) => {
      info.push({ data: doc.data(), id: doc.id });
    });
    if (info.length !== 0) {
      const refOfOrder = doc(db, "listClientNumberOrder", info[0].id);
      //updateDoc(refOfOrder, { numero: info[0].data.numero + orders.length });
      try {
        await runTransaction(db, async (transaction) => {
          const sfDoc = await transaction.get(refOfOrder);
          const newUmber = sfDoc.data()?.numero + orders.length;
          transaction.update(refOfOrder, {
            numero: newUmber,
          });
        });
        console.log("Transaction successfully committed!");
      } catch (e) {
        console.log("Transaction failed: ", e);
      }
    }
    console.log("info:", info);

    addDoc(ordersxusersRef, { email: location.state.email, fecha: dia });
    setLoadingOrders(false);
    setSuccess(true);
    if (textOrder.length !== 0) {
      //console.log(textOrder);
      for (let i = 0; i < textOrder.length; i++) {
        const jsonString = JSON.stringify(textOrder[i]);
        console.log("****************");
        console.log(textOrder[i][0].split(":")[0]);
        console.log(textOrder[i][textOrder[i].length - 1].split(":")[0]);
        console.log(textOrder[i].length);
        console.log(location.state.email);
        console.log(jsonString);
        const lisOrders = collection(db, "lisOrders");
        addDoc(lisOrders, {
          email: location.state.email,
          in: Number(textOrder[i][0].split(":")[0]),
          fi: Number(textOrder[i][textOrder[i].length - 1].split(":")[0]),
          text: jsonString,
        });
      }
    }
  };

  useEffect(() => {
    const lisNew = async () => {
      if (lastNumber === 0) {
        return;
      }
      await newOrders();
    };
    lisNew();
  }, [lastNumber]);
  const handleForce = (data: any, fileInfo: any) => {
    let ordenesAux = [];
    setLoading(true);
    for (let aux = 1; aux < data.length; aux++) {
      if (
        data[aux][0] === "" ||
        data[aux][2] === "" ||
        data[aux][3] === "" ||
        data[aux][4] === "" ||
        data[aux][5] === "" ||
        data[aux][6] === "" ||
        data[aux][7] === "" ||
        data[aux][8] === "" ||
        data[aux][9] === "" ||
        data[aux][11] === ""
      ) {
        setError(true);
        setErrorMsg(erroMsg + " Nº " + aux + " en el csv subido");
        console.log(aux, ":", data[aux]);
      } else {
        ordenesAux.push({
          Id: aux,
          Guia: data[aux][0],
          Destinatario: data[aux][1],
          Direccion: data[aux][2],
          Ciudad: data[aux][3],
          Contacto: data[aux][4],
          Bultos: data[aux][5],
          Kilos: data[aux][6],
          Alto: data[aux][7],
          Ancho: data[aux][8],
          Largo: data[aux][9],
          Observaciones: data[aux][10],
          Destino: data[aux][11],
        });
      }
    }
    setOrders(ordenesAux);
  };

  useEffect(() => {
    // Return early, if this is the first render:
    if (loading && orders.length > 1) {
      setLoading(false);
      setTable(true);
    }
    if (loadingOrders) {
      console.log("llego a llamado");
      handleNew();
    }
  }, [loading, orders, loadingOrders]);

  if (rutInit === "") {
    return <div></div>;
  }
  return (
    <div>
      <div className="w-screen h-screen bg-[#FFFFFF] overflow-hidden">
        {/* navbar */}
        <NavbarPag />
        <div className="h grid grid-cols-4 gap-4 content-start mt-10">
          <div></div>
          <div>Selecciona archivo excel (csv): </div>
          <div>
            <CSVReader
              cssClass="react-csv-input"
              parserOptions={{
                encoding: "ISO-8859-1",
              }}
              onFileLoaded={handleForce}
            />
          </div>
        </div>
        <div className=" grid grid-cols-4 gap-4 content-start mt-2 mb-5">
          <div></div>
          <div>
            {error && (
              <p className=" font-bold text-red-600 text-left mt-5">
                {erroMsg}
              </p>
            )}
            {success && (
              <p className=" font-bold text-sky-600 text-left mt-5">
                Ordenes subidas exitosamente!!
              </p>
            )}
          </div>
          <div>
            {(loading || loadingOrders) && (
              <div style={{ marginBottom: "5%" }}>
                <ReactLoading
                  type={"spin"}
                  color={"blue"}
                  height={"10%"}
                  width={"10%"}
                />
              </div>
            )}
          </div>
        </div>
        {table && (
          <>
            <div className="ag-theme-quartz" style={{ height: "65%" }}>
              <AgGridReact rowData={orders} columnDefs={colDefs} />
            </div>
            {!error && (
              <button
                className="mt-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg"
                onClick={handleSubmit}
              >
                Aprobar Ordenes
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default Dashboard;
