import React from "react";
import reparto from "../images/reparto.png";
import disponible from "../images/disponible.png";
import domicilio from "../images/domicilio.png";
import {
  GoogleMap,
  Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
const markerIcon = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
interface LatLngLiteral {
  lat: number;
  lng: number;
}

const containerStyle = {
  width: "100%",
  height: "400px",
};

function MyGoogleMapMarker({ data }: any) {
  console.log("data", data);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA_CmLQuMhhT7kbGhe0vhEMwxcRz1TlGGA",
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={data[0].center}
      zoom={13}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {data.map((e: any) => {
        return (
          <Marker
            position={e.center}
            icon={
              e.setate === 2 ? domicilio : e.setate === 3 ? reparto : disponible
            }
          />
        );
      })}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyGoogleMapMarker);
