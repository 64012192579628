import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import citiesData from "../../cities/cities.json";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { es } from "date-fns/locale/es";
import MyGoogleMapMarker from "../../components/MyGoogleMapMarker";
import NavbarPag from "../../components/NavbarPag";
import { Any } from "react-spring";
import db from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import PieG from "../../components/PieG";
import reparto from "../../images/reparto.png";
import disponible from "../../images/disponible.png";
import domicilio from "../../images/domicilio.png";
import { DefaultTable } from "../../components/DefaultTable";
import { useData } from "../../contexts/DataContext";
import { useNavigate } from "react-router-dom";
interface Data {
  doc: string;
  field: string;
  where: string;
}
interface Data2 {
  doc: string;
  field: string;
  where: string;
}
interface Data3 {
  doc: string;
  field1: string;
  field2: string;
  field3: string;
  where1: string;
  where2: number;
  where3: number;
}
const ControlRutas = () => {
  const { rutInit } = useData();
  const navigate = useNavigate();
  useEffect(() => {
    if (rutInit === "") {
      navigate("/");
    }
  }, []);
  //console.log(citiesData);
  const options = Object.entries(citiesData).map(([value, label]) => ({
    value,
    label,
  }));
  //console.log(options);
  registerLocale("es", es);
  let lisState = ["INICIAL", "RETIRADO", "ENTREGADO", "NO ENTREGADO"];
  const [state, setState] = useState("");
  const [nodataOd, setNodataOd] = useState(false);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [nota, setNota] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [citise, setCitise] = useState("");
  const [nuEn, setNuEn] = useState(0);
  const [nuNoEn, setNoNuEn] = useState(0);
  const [nuRe, setNuRe] = useState(0);
  const [nameLis, setNameLis] = useState([]);
  const [mapLis, setMapLis] = useState([]);
  const [lisV, setLisV] = useState([]);
  const [nameDisable, setNameDisable] = useState(true);
  let photo = "";
  const [startDate, setStartDate] = useState(new Date());
  const [startDateInt, setStartDateInt] = useState("");
  const [startDateEnd, setStartDateEnd] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [cordenadas, setCordenadas] = useState({
    lat: 4.749376,
    lng: -74.0657156,
  });
  const [rut, setRut] = useState("");
  const [errorOd, setErrorOd] = useState(false);
  const getWhere = async (data: Data) => {
    // console.log("getWhere", data.where);
    try {
      const q = query(
        collection(db, data.doc),
        where(data.field, "==", data.where)
      );
      const querySnapshot = await getDocs(q);
      const info: any = [];
      querySnapshot.forEach((doc) => {
        info.push({ id: doc.id, data: doc.data() });
      });
      const res = { error: false, data: info };
      return res;
    } catch {
      const res = { error: false, data: [] };
      res.error = true;
      return res;
    }
  };
  const getWhereString = async (data: Data2) => {
    // console.log("getWhere", data.where);
    try {
      const q = query(
        collection(db, data.doc),
        where(data.field, "==", data.where)
      );
      const querySnapshot = await getDocs(q);
      const info: any = [];
      querySnapshot.forEach((doc) => {
        info.push({ id: doc.id, data: doc.data() });
      });
      const res = { error: false, data: info };
      return res;
    } catch {
      const res = { error: false, data: [] };
      res.error = true;
      return res;
    }
  };
  const [odValue, setOdValue] = useState("");
  /***const handleCitiseChange = async (newValue: any) => {
    console.log("newValue:", newValue);
    setCitise(newValue.value); */
  const handleInputChange = (newValue: any) => {
    setName(newValue.value);
  };
  const getWhereThree = async (data: Data3) => {
    console.log("getWhereThree", data);

    try {
      const q = query(
        collection(db, data.doc),

        where(data.field1, "==", data.where1),
        where(data.field2, ">=", data.where2),
        where(data.field3, "<=", data.where3)
      );
      const querySnapshot = await getDocs(q);
      const info: any = [];
      querySnapshot.forEach((doc) => {
        info.push({ id: doc.id, data: doc.data() });
      });
      const res = { error: false, data: info };
      return res;
    } catch (error) {
      console.error("Error en la consulta:", error);
      const res = { error: true, data: [] };
      return res;
    }
  };
  const search = async (event: any) => {
    event.preventDefault();
    if (name === "") {
      //return;
    }
    const dataUset = await getWhere({
      doc: "driverUsers",
      field: "name",
      where: name,
    });
    console.log("dataUset:", dataUset);
    const lisUser = dataUset.data[0].data;
    setErrorOd(true);
    console.log("lisUser:", dataUset.data.length);
    setRut(lisUser.rut);
    let initFecha = new Date(startDate);
    let endFecha = new Date(startDate);
    initFecha.setHours(0, 0, 0, 0);
    endFecha.setHours(23, 59, 59, 999);
    console.log(startDate);
    console.log(initFecha);
    console.log(endFecha);
    const user = await getWhereThree({
      doc: "driverRecord",
      field1: "name",
      field2: "date",
      field3: "date",
      where1: name,
      where2: initFecha.getTime(),
      where3: endFecha.getTime(),
    });
    console.log(user);
    const dataMap: any = [];
    const dataLisV: any = [];
    let initH = 0;
    let finH = 0;
    let entrega = 0;
    let noEntrega = 0;
    let retiro = 0;
    if (user.data.length !== 0) {
      user.data.forEach((element: any) => {
        console.log(element.data);
        if (element.data.setate === 1) {
          retiro += 1;
        } else if (element.data.setate === 2) {
          entrega += 1;
        } else if (element.data.setate === 3) {
          noEntrega += 1;
        }
        if (initH === 0) {
          initH = element.data.date;
        }
        if (initH > element.data.date) {
          initH = element.data.date;
        }
        if (finH === 0) {
          finH = element.data.date;
        }
        if (finH < element.data.date) {
          finH = element.data.date;
        }
        dataMap.push({
          setate: element.data.setate,
          center: {
            lat: Number(element.data.latitude),
            lng: Number(element.data.longitude),
          },
        });
        let Hora1 = new Date(element.data.date);
        const thoras1 = Hora1.getHours();
        const tminutos1 = Hora1.getMinutes();
        let lisState = ["RETIRADO", "ENTREGADO", "NO ENTREGADO"];
        dataLisV.push({
          Od: element.data.uuid,
          Hora: `${thoras1 < 10 ? "0" + thoras1 : thoras1}:${tminutos1 < 10 ? "0" + tminutos1 : tminutos1}`,
          Codigo: lisState[element.data.setate - 1],
        });
      });
      setNuEn(entrega);
      setNoNuEn(noEntrega);
      setNuRe(retiro);
      console.log(dataMap);
      console.log("initH:", initH);
      console.log("finH:", finH);
      let inT = new Date(initH);
      const thoras = inT.getHours();
      const tminutos = inT.getMinutes();
      let fiT = new Date(finH);
      const fhoras = fiT.getHours();
      const fminutos = fiT.getMinutes();
      setStartDateInt(
        `${thoras < 10 ? "0" + thoras : thoras}:${tminutos < 10 ? "0" + tminutos : tminutos}`
      );
      setStartDateEnd(
        `${fhoras < 10 ? "0" + fhoras : fhoras}:${fminutos < 10 ? "0" + fminutos : fminutos}`
      );
      setMapLis(dataMap);
      setLisV(dataLisV);
      setNodataOd(false);
    } else {
      setNodataOd(true);
    }
  };
  const handleCitiseChange = async (newValue: any) => {
    console.log("newValue:", newValue);
    setCitise(newValue.value);
    const orders = await getWhereString({
      doc: "driverUsers",
      field: "city",
      where: newValue.value,
    });
    console.log(orders);
    if (orders.data.length !== 0) {
      const options = orders.data.map((e: any) => {
        return { value: e.data.name, label: e.data.name };
      });
      console.log(options);
      setNameLis(options);
      setNameDisable(false);
    } else {
      setNameDisable(true);
    }
  };
  if (rutInit === "") {
    return <div></div>;
  }
  return (
    <div className={!errorOd ? "w-screen h-screen" : ""}>
      {/* navbar */}
      <NavbarPag />
      <div className=" h-full bg-neutral-200 overflow-hidden flex flex-col items-center">
        <div className=" w-11/12">
          <h2 className="text-2xl text-center p-3">Control Rutas</h2>
          <div className="bg-sky-600 p-2 text-white"></div>
          <article className=" bg-slate-50">
            <div className=" bg-neutral-300 p-2 text-sky-600 mt-4">
              INGRESE LOS DATOS DE LA BUSQUEDA
            </div>
            <div className="flex p-4">
              <Select
                onChange={handleCitiseChange}
                options={options}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-64 mr-4"
              />
              <Select
                onChange={handleInputChange}
                options={nameLis}
                isDisabled={nameDisable}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-64 mr-4"
              />
              <DatePicker
                locale="es"
                selected={startDate}
                onChange={(date) => date && setStartDate(date)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-64 mr-4"
              />

              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={(e) => {
                  search(e);
                }}
              >
                Buscar
              </button>
            </div>
          </article>
          <div className=" m-2 p-3">
            {nodataOd ? (
              <div className="text-2xl text-sky-700">
                NO EXISTE INFORMACION DE LA ORDEN
              </div>
            ) : (
              ""
            )}
          </div>
          {errorOd ? (
            <article className=" bg-slate-50 flex">
              <div className="flex p-3">
                Entregas:
                <img src={domicilio} alt="domicilio   " />
              </div>
              <div className="flex p-3">
                No Entregas: <img src={disponible} alt="disponible  " />
              </div>
              <div className="flex p-3">
                Retiros:
                <img src={reparto} alt="reparto " />
              </div>
            </article>
          ) : (
            ""
          )}
          {errorOd ? (
            <article className="flex p-5">
              <div className=" w-1/2 p-3 ">
                <div className=" w  bg-neutral-300">
                  <div className=" text-sky-700 p-1 pl-3">Punto Destino</div>
                  <div>
                    {mapLis.length !== 0 ? (
                      <MyGoogleMapMarker data={mapLis} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className=" w-1/2 p-3 ">
                <div className=" bg-neutral-300">
                  <div className=" text-sky-700 p-1 pl-3">Resumen de Ruta</div>
                </div>
                <div className=" bg-white">
                  <div>Nombre: {name}</div>
                  <div>Rut: {rut}</div>
                  <div>Primera Gestion: {startDateInt}</div>
                  <div>Ultima Gestion: {startDateEnd}</div>

                  <div>Entregas:{nuEn}</div>
                  <div>No Entregas:{nuNoEn}</div>
                  <div>Retiros:{nuRe}</div>
                  <div>Total:{nuEn + nuNoEn + nuRe}</div>
                </div>
              </div>
            </article>
          ) : (
            ""
          )}
          {!nodataOd && errorOd ? (
            <article className=" flex mb-10">
              <div className="  w-1/2 h-96 ">
                <div className=" pl-40">
                  <PieG num={{ noEn: nuNoEn, en: nuEn, re: nuRe }} />
                </div>
              </div>
              <div className=" w-1/2 justify-center text-center pt-40">
                <h5 className="text-xl">Efectividad</h5>
                <div className="text-8xl">
                  {Math.floor(((nuEn + nuRe) * 100) / (nuEn + nuNoEn + nuRe))}%
                </div>
              </div>
            </article>
          ) : (
            ""
          )}
          {!nodataOd && errorOd ? (
            <article>
              <DefaultTable tableRows={lisV} />
            </article>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ControlRutas;
