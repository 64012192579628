import React from "react";
import {
  GoogleMap,
  Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
const markerIcon = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
interface LatLngLiteral {
  lat: number;
  lng: number;
}

const containerStyle = {
  width: "100%",
  height: "400px",
};

function MyGoogleMap({ center }: { center: LatLngLiteral }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA_CmLQuMhhT7kbGhe0vhEMwxcRz1TlGGA",
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyGoogleMap);
