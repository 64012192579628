import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./views/login/index";
import Auth from "./views/Auth/index";
import Register from "./views/register/index";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./views/dashboard/index";
import Search from "./views/search/index";
import SearchOd from "./views/searchOd";
import ControlRutas from "./views/controlRutas";
import { DataProvider } from "./contexts/DataContext";
import RegisterUser from "./views/registerUser";

const Home = (): any => (
  <h1 className="text-3xl font-bold underline">Hello world!</h1>
);
const SearchPage = (): any => <h1> Search</h1>;

function App() {
  return (
    <DataProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Auth />}>
            <Route index element={<Login />} />
            <Route index path="register" element={<Register />} />
          </Route>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="search" element={<Search />} />
          <Route path="searchOD" element={<SearchOd />} />
          <Route path="controlRutas" element={<ControlRutas />} />
          <Route path="RegisterUser" element={<RegisterUser />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </div>
    </DataProvider>
  );
}

export default App;
