import React, { createContext, useContext, useState, ReactNode } from "react";

interface Data {
  od: string;
  setOd: React.Dispatch<React.SetStateAction<string>>;
  rutInit: string;
  setRutInit: React.Dispatch<React.SetStateAction<string>>;
  profile: string;
  setProfile: React.Dispatch<React.SetStateAction<string>>;
}

const DataContext = createContext<Data | undefined>(undefined);

export const DataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [od, setOd] = useState("");
  const [rutInit, setRutInit] = useState("");
  const [profile, setProfile] = useState("");
  return (
    <DataContext.Provider
      value={{
        od,
        setOd,
        rutInit,
        setRutInit,
        profile,
        setProfile,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useTheme debe ser utilizado dentro de un ThemeProvider");
  }
  return context;
};
