import { Link, useNavigate } from "react-router-dom";
import { Outlet, useLocation } from "react-router-dom";
import { useData } from "../contexts/DataContext";
const NavbarPag = () => {
  const { setOd, profile } = useData();
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigate = (path: string) => {
    navigate(path, {
      state: {
        email: location.state.email,
        name: location.state.name,
      },
    });
  };
  return (
    <div className="w-screen py-6 px-5 lg:px-64 bg-[#0084C7] flex justify-between text-neutral-300">
      <span className="text-lg font-semibold text-white ">SCB EXPRESS</span>

      <ul className="hidden md:flex items-center space-x-5">
        <li
          className="text-white cursor-pointer"
          onClick={() => {
            setOd("");
            handleNavigate("/dashboard");
          }}
        >
          Informar
        </li>
        <li
          className="text-white cursor-pointer"
          onClick={() => {
            setOd("");
            handleNavigate("/search");
          }}
        >
          Consultar
        </li>
        <li
          className="text-white cursor-pointer"
          onClick={() => {
            handleNavigate("/searchOD");
          }}
        >
          Consultar OD
        </li>
        {profile !== "" && profile !== undefined ? (
          <>
            <li
              className="text-white cursor-pointer"
              onClick={() => {
                setOd("");
                handleNavigate("/controlRutas");
              }}
            >
              Control Rutas
            </li>
            <li
              className="text-white cursor-pointer"
              onClick={() => {
                setOd("");
                handleNavigate("/RegisterUser");
              }}
            >
              Registrar Usuario
            </li>
          </>
        ) : (
          <></>
        )}
      </ul>

      {/* hamburger menu */}
      <div className="space-y-1 group md:hidden">
        <div className="w-6 h-1 bg-white"></div>
        <div className="w-6 h-1 bg-white"></div>
        <div className="w-6 h-1 bg-white"></div>

        {/* menu */}
        <ul className="bg-[#252525] w-screen pb-10 absolute -top-full group-focus:top-0 right-0 duration-150 flex flex-col space-y-3 justify-end">
          <button className="px-10 py-8 relative ml-auto">
            <div className="w-6 h-1 rotate-45 absolute bg-white"></div>
            <div className="w-6 h-1 -rotate-45 absolute bg-white"></div>
          </button>
          <li className="flex text-white justify-center w-full py-4 hover:bg-[#202020]">
            Informar
          </li>
          <li className="flex text-white justify-center w-full py-4 hover:bg-[#202020]">
            Consultar
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarPag;
