// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDUThNWlKqbqsqIcTb8RdXaQ8PEVXymm8w",
  authDomain: "scb-web-d7866.firebaseapp.com",
  projectId: "scb-web-d7866",
  storageBucket: "scb-web-d7866.appspot.com",
  messagingSenderId: "929736639694",
  appId: "1:929736639694:web:99cce681c17d71e6d9fc9f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default getFirestore();
